import { useEffect } from 'react';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/nextjs';
import { useAuth } from 'src/utils/auth';
import { useMarketplaceContext } from 'src/contexts/marketplace';

export function use404Tracking() {
  const router = useRouter();
  const { user } = useAuth();
  const { marketplace } = useMarketplaceContext();

  useEffect(() => {
    Sentry.withScope((scope) => {
      // Add user context if available
      if (user) {
        scope.setUser({
          id: user.id.toString(),
          email: user.email,
        });
      }

      // Add marketplace context if available
      if (marketplace && marketplace.id && marketplace.name) {
        scope.setTag('marketplace', marketplace.id.toString());
        scope.setTag('marketplaceName', marketplace.name);
      }

      // Add request context
      scope.setExtra('request', {
        path: router.asPath,
        query: router.query,
        referrer: document.referrer,
        userAgent: window.navigator.userAgent,
        timestamp: new Date().toISOString(),
        url: window.location.href,
        pathname: router.pathname,
      });

      // Set error context
      scope.setTag('errorType', '404');
      scope.setLevel('warning');

      // Capture the event
      Sentry.captureMessage('404 Page Not Found', {
        level: 'warning',
        tags: {
          errorType: '404',
          path: router.asPath,
          marketplace: marketplace?.id?.toString(),
        },
        extra: {
          referrer: document.referrer,
        },
      });
    });
  }, [router.asPath, router.query, user, marketplace]);
}
