import { Box, Heading, Text, Button } from '@chakra-ui/react';
import Link from 'next/link';
import { use404Tracking } from 'src/utils/hooks/use404Tracking';

export default function Custom404() {
  use404Tracking();

  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, primary.400, primary.600)"
        backgroundClip="text"
      >
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Page Not Found
      </Text>
      <Text color={'gray.500'} mb={6}>
        The page you&apos;re looking for does not seem to exist
      </Text>

      <Link href="/" passHref>
        <Button
          colorScheme="primary"
          bgGradient="linear(to-r, primary.400, primary.500, primary.600)"
          color="white"
          variant="solid"
        >
          Go to Home
        </Button>
      </Link>
    </Box>
  );
}
